<template>
    <div style="display:flex">    
        <el-button type="primary" size="mini" @click="editabout">{{!isEdit ? "编辑": "提交"}}</el-button>
    </div>
    <el-descriptions title="关于我们" :column="1" v-if="!isEdit"     style="margin-top: 30px"
>
    <el-descriptions-item label="客服电话">{{aboutForm.cellphone}}</el-descriptions-item>
    <el-descriptions-item label="展示内容">
      <div v-html="aboutForm.content"></div>
    </el-descriptions-item>
  </el-descriptions>
    <el-form
    :model="aboutForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    style="margin-top: 30px"
    class="demo-ruleForm"
    title="关于我们"
    v-else
  >
    <el-form-item label="客服电话" size="small" prop="title" style="width: 50%">
      <div style="display: flex">
        <el-input
          v-model="aboutForm.cellphone"
          clearable
          placeholder="请输入客服电话"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="课程详情" style="width: 80%">
      <vue-ueditor-wrap
        v-model="aboutForm.content"
        :config="editorConfig"
        editor-id="courseInfo"
      ></vue-ueditor-wrap>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
    data(){
        return{
            aboutForm:{
                cellphone:'15910404203',
                content:''
            },
            rules:{
                cellphone: [
                        { required: true, message: '请输入客服电话！', trigger: 'blur' }
                        //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                 content: [
                        { required: true, message: '请输入关于我们！', trigger: 'blur' }
                        //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
            },
            isEdit:false

        }
    },
    created(){
        const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '关于我们',
        index: '/aboutusmange'
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getabout()
    },
    methods:{
        async editabout(){
            if(this.isEdit){
                const res = await this.$http.post('/nktdec/aboutus/aboutus',this.aboutForm)
                console.log(res.data)
            }
            this.isEdit = !this.isEdit
        },
        async getabout(){
            const res = await this.$http.get('/nktdec/aboutus/aboutus')
            console.log(res.data.result)
            if(!res.data.result && typeof(res.data.result)!="”undefined”" && res.data.result!=0){
                this.isEdit = true
            }else{
                this.isEdit = false
                this.aboutForm.cellphone = res.data.result.cellphone
                this.aboutForm.content = res.data.result.content
            }
        }
    },
    computed: {
        editorConfig() {
            return this.$store.state.editorConfig
        },
    }
}
</script>